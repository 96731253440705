import React, { useState, useMemo, useEffect } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SelectInputUncontrolled } from '@/components/SelectInput/SelectInputUncontrolled';
import { Button } from '@/components/Button';

import { ContentLayout } from '@/components/ContentLayout/ContentLayout';
import DateRange, { RangeValue } from '@/components/DateRange/DateRange';
import DocumentsCount from '@/components/DocumentsCount/DocumentsCount';
import DocumentsTable from '@/components/Table/DocumentsTable/DocumentsTable';
import SortInput, { SortOrders } from '@/components/SortInput/SortInput';
import { Spinner } from '@/components/Spinner';
import SwitchUncontrolled from '@/components/Switch/SwitchUncontrolled';
import TextFilter from '@/components/TextFilter/TextFilter';
import { useGetDocumentTypesQuery, useGetAllDocumentsQuery } from '@/api/documentsApi';
import { getRangeFilterValue } from '@/utils/dates';
import DocumentsTransfer from '@/pages/admin/DocumentsTransfer';
import BackButton from '@/components/Button/BackButton';
import { scrollToTableTop, scrollToTop } from '@/utils/scroll';
import { DocumentListItem } from '@/types/document';
import { ADMIN_DOCUMENTS_COLUMNS } from '@/components/Table/DocumentsTable/columns/adminDocuments';

export const defaultPageSize = 10;
export const defaultPageNumber = 1;
export const defaultSortField = 'agreementDate';
export const defaultSortOrder = 'desc';

interface AllDocumentsParams {
  user?: string;
  showBackButton?: boolean;
}

const AllDocuments = (params?: AllDocumentsParams) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const ALL_FILTER = t('DocumentTypes.all');
  const [dateRange, setDateRange] = useState<RangeValue>([null, null]);
  const [userFilter, setUserFilter] = useState<string | undefined>(undefined);
  const [clientFilter, setClientFilter] = useState<string | undefined>(undefined);
  const [typeFilter, setTypeFilter] = useState(ALL_FILTER);

  const [sortBy, setSortBy] = useState(defaultSortField);
  const [sortOrder, setSortOrder] = useState<SortOrders>(defaultSortOrder);
  const [pageNumber, setPageNumber] = useState(defaultPageNumber);
  const [isTransferMode, setIsTransferMode] = useState<boolean>(false);
  const [transferAll, setTransferAll] = useState<boolean>(false);
  const [needToToggleRecords, setNeedToToggleRecords] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<
    Record<string, DocumentListItem | undefined>
  >({});
  const meta = searchParams.get('meta');

  const { data: documentTypes } = useGetDocumentTypesQuery();
  const {
    data: documents,
    isLoading: isDocumentsLoading,
    isFetching: isDocumentsFetching,
  } = useGetAllDocumentsQuery({
    filters: {
      offset: defaultPageSize * (pageNumber - 1),
      limit: defaultPageSize,
      sortBy,
      sortOrder,
      client: clientFilter === '' ? undefined : clientFilter,
      userId: params?.user,
      user: userFilter || undefined,
      type: typeFilter === ALL_FILTER ? undefined : typeFilter,
      meta: meta || undefined,
      agreementDateFrom: getRangeFilterValue(dateRange, 0),
      agreementDateTo: getRangeFilterValue(dateRange, 1),
    },
  });

  useEffect(() => {
    if (!documents?.data.length) return;

    const allSelected = documents.data.every(document => !!selectedRows[document.id]);

    setTransferAll(allSelected);
    setNeedToToggleRecords(false);
  }, [selectedRows, documents]);

  const documentTypesFilterOptions = useMemo(() => {
    if (!documentTypes) return [{ value: ALL_FILTER, label: ALL_FILTER }];

    const types = documentTypes.map(documentType => ({
      value: documentType.type,
      label: documentType.type,
    }));

    return [{ value: ALL_FILTER, label: ALL_FILTER }, ...types];
  }, [documentTypes]);

  const sortByOptions = [
    { value: 'title', label: t('AllDocuments.columns.description') },
    { value: 'client', label: t('AllDocuments.columns.client') },
    { value: 'agreementDate', label: t('AllDocuments.columns.date') },
    { value: 'type', label: t('AllDocuments.columns.type') },
  ];

  const rows = useMemo(() => {
    return documents?.data.map(document => ({
      ...document,
      selection: !!selectedRows[document.id],
    }));
  }, [documents?.data, selectedRows]);

  const transferringDocuments = useMemo(
    () => Object.values(selectedRows).filter(document => !!document),
    [selectedRows]
  );

  useEffect(() => {
    setSelectedRows({});
    setPageNumber(1);
  }, [meta]);

  const onTypeChanged = (value: string) => {
    setSelectedRows({});
    setPageNumber(1);
    setTypeFilter(value);
    scrollToTableTop();
  };

  const onUserChanged = (value: string) => {
    setSelectedRows({});
    setPageNumber(1);
    setUserFilter(value);
    scrollToTableTop();
  };

  const onClientChanged = (value: string) => {
    setSelectedRows({});
    setPageNumber(1);
    setClientFilter(value);
    scrollToTableTop();
  };

  const onDateRangeChanged = (value: RangeValue) => {
    setSelectedRows({});
    setPageNumber(1);
    setDateRange(value);
    scrollToTableTop();
  };

  const onSortByChanged = (value: string) => {
    setPageNumber(1);
    setSortBy(value);
    scrollToTableTop();
  };

  const onSortOrderChanged = (value: SortOrders) => {
    setPageNumber(1);
    setSortOrder(value);
  };

  const onTransfer = () => {
    setPageNumber(1);
    setIsTransferMode(true);
    window.history.pushState({ tfaModal: true }, '', window.location.href);
  };

  const onBack = () => {
    setPageNumber(1);
    if (isTransferMode) {
      scrollToTableTop();
      setIsTransferMode(false);
    } else {
      navigate(-1);
    }
  };

  const onTransferred = () => {
    scrollToTop();
    setSelectedRows({});
    onBack();
  };

  const onTransferAllChanged = () => {
    setTransferAll(prev => !prev);
    setNeedToToggleRecords(true);
  };

  const content = () => {
    return isTransferMode ? (
      <DocumentsTransfer
        documents={transferringDocuments}
        onClose={onBack}
        onComplete={onTransferred}
        userDetails={!!params?.user}
      />
    ) : (
      <>
        <div className="flex flex-1 flex-row justify-between items-center">
          <div className="flex flex-1 flex-row px-25px xl:px-10 mt-[8px]">
            <SwitchUncontrolled
              value={transferAll}
              onChange={onTransferAllChanged}
              text={t('AllDocuments.checkboxes.selectAll')}
              textClassname="text_primary"
            />
            <Button
              type="button"
              className={clsx('button_table button_table_label !py-[8px] ml-[25px]')}
              disabled={!transferringDocuments.length}
              onClick={onTransfer}
            >
              {t('AllDocuments.buttons.transfer')}
            </Button>
          </div>
          <DocumentsCount isLoading={isDocumentsLoading} total={documents?.total} />
        </div>
        <div
          className={clsx(
            'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-2 2xl:grid-cols-3',
            'gap-y-[3px] md:gap-x-[3px] lg:mb-[37px] mb-0'
          )}
        >
          {params?.user ? (
            <TextFilter
              initValue={clientFilter}
              label={t('AllDocuments.filters.client')}
              placeholder={t('AllDocuments.filters.clientPlaceholder')}
              onSubmit={onClientChanged}
            />
          ) : (
            <TextFilter
              initValue={userFilter}
              label={t('AllUsers.filters.user')}
              placeholder={t('AllUsers.filters.userPlaceholder')}
              onSubmit={onUserChanged}
            />
          )}
          <SelectInputUncontrolled
            label={t('Authenticate.labels.documentType')}
            name="documentType"
            options={documentTypesFilterOptions}
            value={typeFilter}
            setValue={onTypeChanged}
          />
          <div className="md:block lg:hidden xl:block 2xl:hidden" />
          <DateRange label="Date" value={dateRange} onChange={onDateRangeChanged} />
          <div className="md:col-span-1 lg:col-span-2 xl:col-span-1 2xl:col-span-2" />
          <div className="mt-[10px]">
            <SortInput
              label={t('AllDocuments.sortBy')}
              name="sortBy"
              options={sortByOptions}
              sortBy={sortBy}
              setSortBy={onSortByChanged}
              sortOrder={sortOrder}
              setSortOrder={onSortOrderChanged}
              isOneLine
            />
          </div>
        </div>

        {isDocumentsFetching ? (
          <Spinner size="medium" />
        ) : (
          <DocumentsTable
            columns={ADMIN_DOCUMENTS_COLUMNS}
            documents={rows ?? []}
            isLoadingData={isDocumentsFetching}
            setPageNumber={setPageNumber}
            allRowsSelected={transferAll}
            needToToggleRecords={needToToggleRecords}
            onSelectedRowsChanged={setSelectedRows}
            allDocumentsNumber={documents?.total}
            currentPageNumber={pageNumber}
            pageSize={defaultPageSize}
          />
        )}
      </>
    );
  };

  return params?.user ? (
    <>
      {content()}
      <BackButton title={t('UserProfile.buttons.back')} onBack={onBack} />
    </>
  ) : (
    <ContentLayout title={t('AllDocuments.title')}>{content()}</ContentLayout>
  );
};

export default AllDocuments;
