import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import QRCode from 'qrcode';
import { SubmitHandler, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';

import clsx from 'clsx';
import { useSetup2FAMutation } from '@/api/authApi';
import { Modal } from './Modal';
import { Spinner } from '../Spinner';
import { Paragraph } from '../Typography/Paragraph';
import { TwoFactorStep } from '../TwoFactorStep';
import { TextInput } from '../TextInput';
import {
  TwoFactorSetupFormData,
  twoFactorSetupFormSchema,
} from '@/forms/twoFactorSetupForm';
import { Button } from '../Button';
import { ErrorMessage } from '../ErrorMessage';
import { useAppSelector } from '@/utils/hooks';
import { orgName } from '@/constants/app';

interface TwoFactorAuthModalProps {
  onClose: () => void;
  isOpen: boolean;
  code: string | null | undefined;
}

export const TwoFactorSetupModal = ({
  isOpen,
  onClose,
  code,
}: TwoFactorAuthModalProps) => {
  const { t } = useTranslation();

  const email = useAppSelector(state => state.auth.email);

  const [qrCode, setQrCode] = useState<string>('');

  const [setup2FA, { error, isSuccess }] = useSetup2FAMutation();

  const { handleSubmit, control, watch } = useForm<TwoFactorSetupFormData>({
    resolver: joiResolver(twoFactorSetupFormSchema),
    defaultValues: {
      token: '',
    },
  });

  const token = watch('token');

  useEffect(() => {
    const generateQrCode = async () => {
      if (!code) {
        return;
      }

      const qr = await QRCode.toDataURL(
        `otpauth://totp/${orgName}: ${email ?? '-'}?secret=${code}`
      );
      setQrCode(qr);
    };

    generateQrCode();
  }, [code]);

  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
  }, [isSuccess]);

  const onSubmit: SubmitHandler<TwoFactorSetupFormData> = formData => {
    setup2FA({
      code: code ?? '',
      token: formData.token,
    });
  };

  const handleCloseModal = () => {
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleCloseModal}
      title={t('TwoFactorAuth.modalTitle')}
      size="small"
    >
      <div>
        {!code ? (
          <Spinner className="pb-10" />
        ) : (
          <div>
            <div className="px-[25px] sm:px-7.5 md:px-12.5">
              <Paragraph className="mb-7">
                {t('TwoFactorAuth.setupInstructions')}
              </Paragraph>
              <TwoFactorStep label={t('TwoFactorAuth.scanCode')} number={1} />
              <img
                className={clsx(
                  'm-auto mb-6 mt-6',
                  'max-sm:w-[240px] max-sm:h-[240px] w-[300px] h-[300px]'
                )}
                src={qrCode}
                alt=""
              />
              <TwoFactorStep label={t('TwoFactorAuth.enterKey')} number={2} />
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <TextInput
                className="mt-6"
                control={control}
                name="token"
                label={t('TwoFactorAuth.otk')}
                isRequired
                focus
              />
              <ErrorMessage error={error} resetOn={[token]} />
              <div className="p-6">
                <Button isSubmit className="w-full">
                  {t('Common.submit')}
                </Button>
              </div>
            </form>
          </div>
        )}
      </div>
    </Modal>
  );
};
