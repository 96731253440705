import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import clsx from 'clsx';

import { joiResolver } from '@hookform/resolvers/joi';
import { Title } from '@/components/Typography/Title';
import { TextInput } from '@/components/TextInput';
import { Button } from '@/components/Button';
import { useGetUserDataQuery, useUpdateUserDataMutation } from '@/api/userApi';
import { showSuccessToast } from '@/utils/toast';
import {
  updateProfileDetailsFormSchema,
  UserProfileData,
} from '@/forms/updateProfileDetailsForm';
import { PageTitle } from '@/components/Typography/Title/PageTitle';
import { Spinner } from '@/components/Spinner';
import { TwoFactorAuthModal } from '@/components/Modal/TwoFactorAuthModal';
import { Required } from '@/components/Typography/Required';
import { useModal } from '@/utils/hooks';
import { UserRole } from '@/types/user';

const Account = () => {
  const { t } = useTranslation();

  const { data: profileDetails, isLoading, refetch } = useGetUserDataQuery();
  const [updateUsersData, { isSuccess }] = useUpdateUserDataMutation();

  const isAdmin = profileDetails?.role === UserRole.Admin;

  const {
    isModalOpen: is2FaModalOpen,
    openModal: open2FaModal,
    closeModal: close2FaModal,
  } = useModal();

  const { handleSubmit, control, getValues } = useForm<UserProfileData>({
    resolver: joiResolver(updateProfileDetailsFormSchema),
    values: profileDetails,
    defaultValues: {
      email: '',
      name: '',
      surname: '',
      password: '',
      confirmPassword: '',
    },
  });

  useEffect(() => {
    if (isSuccess) {
      showSuccessToast(t('Account.dataUpdated'));
      refetch();
    }
  }, [isSuccess]);

  const onSubmit: SubmitHandler<UserProfileData> = data => {
    if (data.password) {
      open2FaModal();
      return;
    }

    updateUsersData({
      email: data.email,
      name: data.name,
      surname: data.surname,
    });
  };

  const onSubmitWith2fa = ({ confirmationToken }: { confirmationToken: string }) => {
    const [email, name, surname, password] = getValues([
      'email',
      'name',
      'surname',
      'password',
    ]);

    updateUsersData({
      email,
      name,
      surname,
      password,
      confirmationToken,
    });
    close2FaModal();
  };

  if (isLoading) return <Spinner size="medium" />;

  return (
    <>
      <PageTitle>{isAdmin ? t('Account.adminTitle') : t('Account.title')}</PageTitle>
      <div className="relative background_page_content border_top">
        <Required />
        <form onSubmit={handleSubmit(onSubmit)}>
          <div
            className={clsx(
              'grid-cols-1 grid grid-rows-auto w-full',
              'xl:gap-3px xl:flex-row pt-0'
            )}
          >
            <Title level="h2" className="px-25px py-7.5 md:px-7.5 xl:py-[42px] xl:px-10">
              {t('CreateAccount.personalInfo')}
            </Title>
            <div
              className={clsx(
                'grid-cols-1 grid grid-rows-auto w-full sm:grid-cols-2 gap-3px'
              )}
            >
              <TextInput
                label={t('CreateAccount.labels.name')}
                name="name"
                isRequired
                control={control}
              />
              <TextInput
                label={t('CreateAccount.labels.surname')}
                name="surname"
                isRequired
                control={control}
              />
            </div>

            <Title
              level="h2"
              className="px-25px py-7.5 md:px-7.5 xl:py-[42px] xl:px-10 text-left"
            >
              {t('CreateAccount.loginInfo')}
            </Title>
            <TextInput
              label={t('CreateAccount.labels.email')}
              name="email"
              control={control}
              disabled
              inputClassName="border-none background_secondary"
            />
            <div
              className={clsx(
                'grid-cols-1 grid grid-rows-auto w-full sm:grid-cols-2 gap-3px'
              )}
            >
              <TextInput
                label={t('CreateAccount.labels.password')}
                name="password"
                isPassword
                isRequired
                control={control}
              />
              <TextInput
                label={t('CreateAccount.labels.confirmPassword')}
                name="confirmPassword"
                isPassword
                isRequired
                control={control}
              />
            </div>
          </div>
          <Button isSubmit className="w-full" disabled={isLoading}>
            {t('Account.updateDetails')}
          </Button>
        </form>
      </div>
      <TwoFactorAuthModal
        isOpen={is2FaModalOpen}
        onClose={close2FaModal}
        onTokenSubmit={onSubmitWith2fa}
      />
    </>
  );
};

export default Account;
