import clsx from 'clsx';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LogoutIcon from '@/assets/icons/logout.svg';
import AllNFTsIcon from '@/assets/icons/nfts.svg';
import CreateNFTIcon from '@/assets/icons/create-nft.svg';
import DashboardIcon from '@/assets/icons/dashboard.svg';
import AccountIcon from '@/assets/icons/account.svg';
import AdminIcon from '@/assets/icons/admin.svg';
import HelpIcon from '@/assets/icons/help.svg';
import ActivityIcon from '@/assets/icons/activity.svg';
import ReportsIcon from '@/assets/icons/reports.svg';
import { ROUTES } from '@/constants/routes';
import { useGetUserDataQuery } from '@/api/userApi';
import { UserRole } from '@/types/user';

const DashboardSidebar = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { data: profileDetails } = useGetUserDataQuery();

  const sidebarList =
    profileDetails?.role === UserRole.Admin
      ? [
          {
            label: '',
            options: [
              {
                name: t('Dashboard.Sidebar.dashboard'),
                icon: DashboardIcon,
                url: ROUTES.HOME,
                pathName: '',
              },
            ],
          },
          {
            label: '',
            options: [
              {
                name: t('Dashboard.Sidebar.adminAccount'),
                icon: AdminIcon,
                url: ROUTES.ACCOUNT,
                pathName: 'account',
              },
              {
                name: t('Dashboard.Sidebar.docs'),
                icon: AllNFTsIcon,
                url: ROUTES.DOCUMENTS,
                pathName: 'documents',
              },
              {
                name: t('Dashboard.Sidebar.users'),
                icon: AccountIcon,
                url: ROUTES.USERS,
                pathName: 'users',
              },
              {
                name: t('Dashboard.Sidebar.invitations'),
                icon: AccountIcon,
                url: ROUTES.INVITATIONS,
                pathName: 'invitations',
              },
              {
                name: t('Dashboard.Sidebar.statistics'),
                icon: ReportsIcon,
                url: ROUTES.STATISTICS,
                pathName: 'statistics',
              },
              {
                name: t('Dashboard.Sidebar.activityLog'),
                icon: ActivityIcon,
                url: ROUTES.ACTIVITY,
                pathName: 'activity-log',
              },
            ],
          },
          {
            label: '',
            options: [
              {
                name: t('Dashboard.Sidebar.help'),
                icon: HelpIcon,
                url: ROUTES.HELP,
                pathName: 'help',
              },
              {
                name: t('Dashboard.Sidebar.logout'),
                icon: LogoutIcon,
                url: ROUTES.LOGOUT,
              },
            ],
          },
        ]
      : [
          {
            label: '',
            options: [
              {
                name: t('Dashboard.Sidebar.dashboard'),
                icon: DashboardIcon,
                url: ROUTES.HOME,
                pathName: '',
              },
            ],
          },
          {
            label: t('Dashboard.Sidebar.myDocs'),
            options: [
              {
                name: t('Dashboard.Sidebar.docs'),
                icon: AllNFTsIcon,
                url: ROUTES.DOCUMENTS,
                pathName: 'documents',
              },
              {
                name: t('Dashboard.Sidebar.authenticate'),
                icon: CreateNFTIcon,
                url: ROUTES.CREATE_DOCUMENT,
                pathName: 'create-document',
              },
            ],
          },
          {
            label: t('Dashboard.Sidebar.myProfile'),
            options: [
              {
                name: t('Dashboard.Sidebar.account'),
                icon: AccountIcon,
                url: ROUTES.ACCOUNT,
                pathName: 'account',
              },
            ],
          },
          {
            label: '',
            options: [
              {
                name: t('Dashboard.Sidebar.help'),
                icon: HelpIcon,
                url: ROUTES.HELP,
                pathName: 'help',
              },
              {
                name: t('Dashboard.Sidebar.logout'),
                icon: LogoutIcon,
                url: ROUTES.LOGOUT,
              },
            ],
          },
        ];

  return (
    <div
      className={clsx(
        'dashboard_sidebar',
        'background_primary',
        'w-full md:w-[430px] xl:mt-[152px]',
        'md:py-[55px] md:min-h-full pl-25px',
        'max-md:px-10 md:pl-10 xl:pl-15 xl:fixed max-sm:overflow-y-auto z-[99]',
        'md:overflow-y-auto h-full max-sm:px-25px'
      )}
    >
      {profileDetails
        ? sidebarList.map((item, idx) => (
            <div
              className={clsx(
                'mb-[16px] md:mb-7.5 last:pb-12.5',
                'after:content-[""] after:block after:h-[1px] after:bg-gray-tetriary',
                'xl:after:mr-15 md:after:mr-10 after:mr-25px last:after:hidden',
                'max-md:after:mr-0'
              )}
              key={idx}
            >
              {item.label && (
                <h5 className="dashboard_sidebar_label hidden mb-[16px] md:block">
                  {item.label}
                </h5>
              )}
              <ul>
                {item.options.map(option => (
                  <li
                    key={option.name}
                    className={clsx(
                      'button_navigation duration-200',
                      'hover:border-l-3 hover:pl-[17px]',
                      pathname.split('/')[1] === option.pathName &&
                        'button_navigation_active pl-[17px] border_left'
                    )}
                  >
                    <NavLink
                      to={option.url}
                      className={clsx(
                        'flex items-center mb-[16px] md:mb-[21px] py-[4px]',
                        'md:py-[9px] duration-200'
                      )}
                    >
                      <img src={option.icon} alt="" />
                      <p className="pl-[13px]">{option.name}</p>
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>
          ))
        : null}
    </div>
  );
};

export default DashboardSidebar;
