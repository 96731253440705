import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import clsx from 'clsx';

import React, { useEffect, useMemo, useState } from 'react';
import { ThirdPartyPageView } from '@/components/ThirdPartyPageView';
import { useGetDocumentShareViewQuery } from '../../api/documentsApi';
import { Title } from '@/components/Typography/Title';
import { HistoryTree } from '@/components/HistoryTree';
import { DocumentViewer } from '@/components/DocumentViewer';
import { DocumentParties } from '@/components/DocumentParties';
import { DetailsBlock } from '@/components/DetailsBlock/DetailsBlock';
import { getConvertedDate, getConvertedDateTimestamp } from '@/utils/dates';
import { Spinner } from '@/components/Spinner';
import { ReactComponent as FailureIcon } from '@/assets/icons/cross-round.svg';
import { PageTitle } from '@/components/Typography/Title/PageTitle';
import SharedDocumentSignatures from '@/pages/parties/SharedDocumentSignatures';
import { scrollToSharedDocumentTop } from '@/utils/scroll';
import { SharedDocument } from '@/types/document';

const PartyShareView = () => {
  const { documentId: sharedDocumentId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();

  if (!sharedDocumentId) {
    return null;
  }

  const token = searchParams.get('token') as string;
  const documentId = searchParams.get('active') ?? sharedDocumentId;
  const showSignatories = searchParams.get('signatories') === 'true';

  const [activeDocument, setActiveDocument] = useState<SharedDocument | undefined>(
    undefined
  );

  const { data, isLoading, isError } = useGetDocumentShareViewQuery({
    token,
  });

  const history = useMemo(() => {
    if (!data) return [];
    if (!data.history && !data.signatories?.length) return [];
    return [
      ...(data.history?.previousLinkedDocuments ?? []),
      data,
      ...(data.history?.nextLinkedDocuments ?? []),
    ];
  }, [data]);

  useEffect(() => {
    if (!history.length) {
      setActiveDocument(data);
    } else {
      const document = history.find(doc => doc.id === documentId);
      if (!document) return;
      setActiveDocument(document);
    }
  }, [history]);

  const redirectToDocument = (id?: string) => {
    const document = history.find(doc => doc.id === id);
    if (!document) return;
    setActiveDocument(document);
    setSearchParams(prev => ({
      ...prev,
      token,
      active: document.id,
    }));
    scrollToSharedDocumentTop();
  };

  const redirectToDocumentSignatures = (id?: string) => {
    const document = history.find(doc => doc.id === id);
    if (!document || !document.signatories?.length) return;
    setActiveDocument(document);
    setSearchParams(prev => ({
      ...prev,
      token,
      active: document.id,
      signatories: true,
    }));
    scrollToSharedDocumentTop();
  };

  const subDocumentId = useMemo(() => {
    if (!activeDocument) {
      return sharedDocumentId;
    }
    return activeDocument.id === sharedDocumentId ? undefined : activeDocument.id;
  }, [sharedDocumentId, activeDocument]);

  if (isLoading) {
    return <Spinner fullScreen size="huge" />;
  }

  return (
    <ThirdPartyPageView pageTitle={activeDocument?.title || ''}>
      <div
        className={clsx(
          'background_page_content border_top',
          'flex max-lg:flex-col lg:grid lg:gap-x-[3px]',
          history.length && 'lg:grid-cols-[1fr,2fr,1fr]',
          !history.length && 'lg:grid-cols-[1fr,1fr]',
          isError && 'lg:grid-cols-1'
        )}
      >
        {isError ? (
          <div
            className={clsx(
              'bg-red-tetriary flex items-center justify-center flex-col xl:py-48 py-24'
            )}
          >
            <FailureIcon />
            <PageTitle>{t('PartyApproval.linkExpired.title')}</PageTitle>
            <p
              className={clsx(
                'md:max-w-[940px] text-center text_primary text-18px max-w-[90%]',
                'max-md:text-14px'
              )}
            >
              {t('PartyApproval.linkExpired.description')}
            </p>
          </div>
        ) : (
          <>
            {history.length > 0 && (
              <div className="flex flex-col justify-between flex-1">
                <Title level="h4" className="content-layout">
                  {t('SingleDocumentDetails.labels.history')}
                </Title>
                <div className="mb-auto">
                  <HistoryTree
                    activeDocumentId={activeDocument?.id}
                    isActiveDocumentSignatories={showSignatories}
                    history={history}
                    redirectToDocument={redirectToDocument}
                    redirectToDocumentSignatures={redirectToDocumentSignatures}
                  />
                </div>
              </div>
            )}
            {activeDocument && showSignatories && (
              <SharedDocumentSignatures token={token} activeDocument={activeDocument} />
            )}
            {activeDocument && !showSignatories && (
              <div className="flex flex-col justify-start print-hide flex-1">
                <Title level="h4" className="content-layout">
                  {t('SingleDocumentDetails.labels.preview')}
                </Title>
                <div className={clsx('bg-white-primary')}>
                  {!!activeDocument && (
                    <DocumentViewer
                      target="share"
                      documentId={sharedDocumentId}
                      subDocumentId={subDocumentId}
                      token={token}
                      className="mt-0"
                    />
                  )}
                </div>
              </div>
            )}
            <div className="flex flex-col justify-between flex-1">
              <Title level="h4" className="content-layout">
                {t('SingleDocumentDetails.labels.details')}
              </Title>
              <div className="mb-auto">
                <div>
                  {!!activeDocument?.type && (
                    <DetailsBlock
                      label={t('UploadDocument.labels.type')}
                      text={activeDocument?.type}
                      textClassName="capitalize"
                    />
                  )}
                  {!!activeDocument?.client && (
                    <DetailsBlock
                      label={t('UploadDocument.labels.client')}
                      text={activeDocument?.client}
                    />
                  )}
                  {!!activeDocument?.agreementDate && (
                    <DetailsBlock
                      label={t('UploadDocument.labels.date')}
                      text={getConvertedDate(activeDocument?.agreementDate)}
                    />
                  )}
                  <DetailsBlock
                    label={t('UploadDocument.labels.creationDate')}
                    text={getConvertedDateTimestamp(
                      activeDocument?.createdAt,
                      undefined,
                      true
                    )}
                  />
                  <DetailsBlock label={t('SingleDocumentDetails.labels.status')}>
                    {activeDocument?.authenticatedAt ? (
                      <span className="text-green-primary capitalize">
                        {t('SingleDocumentDetails.nftStatus.active')}
                      </span>
                    ) : (
                      <span className="text-yellow-primary capitalize">
                        {t('SingleDocumentDetails.nftStatus.pending')}
                      </span>
                    )}
                  </DetailsBlock>
                </div>
              </div>
              {!showSignatories &&
                activeDocument?.signatories &&
                activeDocument?.signatories?.length > 0 && (
                  <div className="bg-white-primary h-full pb-[52px] mt-[3px]">
                    <p
                      className={clsx(
                        'input_label',
                        'bg-white-primary',
                        'py-3 px-25px md:px-7.5 text-left xl:px-10 xl:py-4 block'
                      )}
                    >
                      {t('AddParties.partiesLabel')}
                    </p>
                    {activeDocument?.signatories?.length && (
                      <DocumentParties
                        parties={activeDocument.signatories}
                        isSimplified
                      />
                    )}
                  </div>
                )}
            </div>
          </>
        )}
      </div>
    </ThirdPartyPageView>
  );
};

export default PartyShareView;
