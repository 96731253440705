import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';

import { NavigationLinks } from '../NavigationLinks';
import { SocialIcons } from '../SocialIcons';
import { ReactComponent as Hamburger } from '@/assets/icons/hamburger.svg';
import { ReactComponent as HamburgerOpened } from '@/assets/icons/expanded-menu.svg';
import { useIsMobile, useLockBodyScroll } from '@/utils/hooks';

const MobileMenu = () => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const isMobile = useIsMobile();
  const location = useLocation();

  useEffect(() => {
    setIsMenuOpen(false);
  }, [location]);

  const handleButtonClick = () => setIsMenuOpen(isOpen => !isOpen);

  useLockBodyScroll(isMenuOpen && isMobile);

  return (
    <div className="xl:hidden">
      <button type="button" className="cursor-pointer" onClick={handleButtonClick}>
        {isMenuOpen ? (
          <HamburgerOpened className="max-md:w-[30px]" />
        ) : (
          <Hamburger className="w-7.5 md:w-10 fill-gray-primary icon_hover" />
        )}
      </button>
      {isMenuOpen && (
        <div
          className={clsx(
            'absolute bg-white-primary w-screen left-0 z-50 flex items-end flex-col',
            'md:pr-10 text-right top-[93px] h-screen md:pt-25px md:top-[105px] xl:hidden',
            'pr-[25px]'
          )}
        >
          <ul className="font-16px tracking-widest leading-10">
            <NavigationLinks
              linkClassName={clsx(
                'border-r-[3px] border-white-primary pr-6',
                'text_link_hover'
              )}
            />
          </ul>
          <SocialIcons className="flex mt-10 mr-[43px] max-md:mr-7.5" />
        </div>
      )}
    </div>
  );
};

export default MobileMenu;
