/* eslint-disable react/destructuring-assignment */
import clsx from 'clsx';
import i18next from 'i18next';
import React, { ClassAttributes, HTMLAttributes } from 'react';
import { NavLink } from 'react-router-dom';
import ArrowIcon from '@/assets/icons/arrow-white.svg';
import { ReactComponent as VerifiedIcon } from '@/assets/icons/verified.svg';
import { ReactComponent as PendingIcon } from '@/assets/icons/pending.svg';
import { ReactComponent as AwaitingIcon } from '@/assets/icons/awaiting.svg';
import { ReactComponent as RejectedIcon } from '@/assets/icons/rejected.svg';
import { getConvertedDate, getConvertedDateTimestamp } from '@/utils/dates';
import { PARTY_APPROVAL_STATUSES } from '@/constants/partyApprovalStatuses';
import SwitchUncontrolled from '@/components/Switch/SwitchUncontrolled';
import { humanFileSize } from '@/utils/fileSize';
import { ROUTES } from '@/constants/routes';
import { constructRoute } from '@/utils/routes';
import { Activity, ActivityAction } from '@/types/activity';
import { DocumentListItem, Signatory, UserDetails } from '@/types/document';

interface TableButtonProps {
  text: string;
  onClick?: () => void;
  className?: string;
}

export interface CustomRowProps {
  canExpand: boolean;
  getToggleRowSelectedProps: any;
  getToggleRowExpandedProps: () => JSX.IntrinsicAttributes &
    ClassAttributes<HTMLSpanElement> &
    HTMLAttributes<HTMLSpanElement>;
  subRows: string | string[];
  isExpanded: boolean;
  original: DocumentListItem;
  depth?: number;
}

export const TableButton = ({ text, onClick, className }: TableButtonProps) => (
  <button
    type="button"
    className={clsx(
      'button_table',
      'py-[6px] px-[10px] flex items-center justify-center background_primary',
      className
    )}
    onClick={onClick}
  >
    {text}
  </button>
);

export const TableCellSwitchButton = (row: CustomRowProps) => {
  return (
    <SwitchUncontrolled
      value={row.original.selection ?? false}
      onChange={value => {
        // eslint-disable-next-line no-param-reassign
        row.original.selection = value;
      }}
    />
  );
};

export const TableCellDescription = (value: string) => (
  <span
    className={clsx(
      'max-w-[700px] text_link_hover hover:cursor-pointer',
      'duration-200 w-full',
      'overflow-hidden text-ellipsis'
    )}
  >
    {value}
  </span>
);

export const TableCellDate = (value: string) => (
  <span className="inline-flex flex-wrap max-w-[700px]">
    {getConvertedDate(value, true)}
  </span>
);

export const TableCellDateTime = (value: string) => (
  <span className="inline-flex flex-wrap max-w-[700px]">
    {getConvertedDate(value, false, true)}
  </span>
);

export const TableCellDateTimestamp = (value: number) => (
  <span className="inline-flex flex-wrap max-w-[700px]">
    {getConvertedDateTimestamp(value, true)}
  </span>
);

export const TableCellType = (value: string, isFullWidth?: boolean) => (
  <span
    className={clsx(
      'background_tetriary',
      'items-center text_primary py-[5px]',
      'py-[2px] text-14px font-bold min-h-[34px] h-auto',
      '!max-w-[150px] uppercase !whitespace-nowrap !text-ellipsis',
      isFullWidth
        ? 'flex items-center justify-center w-full max-sm:text-[12px]'
        : 'px-[20px]'
    )}
  >
    {value}
  </span>
);

export const TableCellStatus = (value: string, isExtended?: boolean) => {
  const status = value ? 'active' : 'pending';

  const isActive = status === 'active';
  const isPending = status === 'pending';

  return (
    <div className={clsx(isExtended && 'inline-flex items-center justify-center')}>
      <span
        className={clsx('capitalize', {
          'text-green-primary': isActive,
          'text-yellow-primary': isPending,
          'mr-[10px]': isExtended,
        })}
      >
        {status}
      </span>
    </div>
  );
};

export const TableCellParties = (value: number) => {
  return <span>{value}</span>;
};

export const TableCellName = (value: string) => (
  <span
    className={clsx(
      'min-w-0 whitespace-nowrap overflow-hidden text-ellipsis',
      'text_link_hover hover:cursor-pointer'
    )}
  >
    {value}
  </span>
);

export const TableCellEmail = (value: string) => (
  <span className={clsx('text_link_hover hover:cursor-pointer')}>{value}</span>
);

export const TableCellNumber = (value: number) => {
  return <span>{value}</span>;
};

export const TableCellStorageSize = (value: number) => {
  const { size, unit } = humanFileSize(value);
  const vals = size?.toString().split('.') || [];

  return (
    <>
      {vals[0] && <span className="table_cell">{vals[0]}</span>}
      {vals[1] && <span className="table_cell">.{vals[1]}</span>}
      {unit && <span className="table_cell p-[2px]">{unit}</span>}
    </>
  );
};

export const TableCellUser = (user?: UserDetails) => {
  return (
    <span
      className={clsx(
        'min-w-0 whitespace-nowrap overflow-hidden text-ellipsis',
        'text_link_hover hover:cursor-pointer',
        'overflow-hidden text-ellipsis'
      )}
    >{`${user?.name ?? ''} ${user?.surname ?? ''}`}</span>
  );
};

export const TableCellHistory = (row: CustomRowProps) => {
  return row.canExpand ? (
    <span
      {...row.getToggleRowExpandedProps()}
      className="flex items-center justify-center max-md:text-13px"
    >
      <span className="mt-[-1px] mr-[8px]">{row.subRows.length}</span>
      <button
        type="button"
        className={clsx(
          'ml-[6px] p-[12px] button_main rounded-full',
          row.isExpanded && 'bg-red-primary',
          'duration-200 background_primary w-[34px] h-[34px]',
          'flex items-center justify-center max-md:p-2 max-md:w-[22px] max-md:h-[22px]'
        )}
        aria-label="arrow"
      >
        <img
          src={ArrowIcon}
          className={clsx(
            row.isExpanded ? 'rotate-180' : 'rotate-0',
            ' pt-[2px] duration-200'
          )}
          alt=""
        />
      </button>
    </span>
  ) : null;
};

interface TableCellPartyStatusProps {
  status: Signatory['status'];
  isExtended?: boolean;
}

export const TableCellPartyStatus = ({
  status,
  isExtended,
}: TableCellPartyStatusProps) => {
  if (!status) {
    return null;
  }

  switch (status) {
    case PARTY_APPROVAL_STATUSES.Approved:
      return isExtended ? (
        <div className="flex items-center">
          <p
            className={clsx(
              'inline-block font-bold text-11px mr-[10px] uppercase text-green-primary'
            )}
          >
            {`${i18next.t('Signatories.statuses.verified')}`}
          </p>
          <VerifiedIcon />
        </div>
      ) : (
        <VerifiedIcon />
      );
    case PARTY_APPROVAL_STATUSES.Signed:
      return isExtended ? (
        <div className="flex items-center">
          <span
            className={clsx(
              'inline-block font-bold text-11px mr-[10px] uppercase text-yellow-primary'
            )}
          >
            {`${i18next.t('Signatories.statuses.unverified')}`}
          </span>
          <PendingIcon />
        </div>
      ) : (
        <PendingIcon />
      );
    case PARTY_APPROVAL_STATUSES.Requested:
      return isExtended ? (
        <div className="flex items-center">
          <span
            className={clsx(
              'inline-block font-bold text-11px mr-[10px] uppercase text-blue-primary'
            )}
          >
            {`${i18next.t('Signatories.statuses.awaiting')}`}
          </span>
          <AwaitingIcon />
        </div>
      ) : (
        <AwaitingIcon />
      );
    case PARTY_APPROVAL_STATUSES.Rejected:
      return isExtended ? (
        <div className="flex items-center">
          <span
            className={clsx(
              'inline-block font-bold text-11px mr-[10px] uppercase text-red-error'
            )}
          >
            {`${i18next.t('Signatories.statuses.rejected')}`}
          </span>
          <RejectedIcon />
        </div>
      ) : (
        <RejectedIcon />
      );

    default:
      return <span>{status}</span>;
  }
};

export const TableCellSimpleAction = ({ action }: { action: string }) => (
  <span>
    {
      // @ts-ignore
      i18next.t(`Activity.actions.${action}`)
    }
  </span>
);

export const TableCellBasicAction = ({ action }: { action: string }) => (
  <span>
    {
      // @ts-ignore
      i18next.t(`Activity.actions.${action}`)
    }
  </span>
);

export const TableCellDetailedAction = ({
  action,
  title,
}: {
  action: string;
  title?: string;
}) => (
  <div className={clsx('flex flex-row ')}>
    {
      // @ts-ignore
      i18next.t(`Activity.actions.${action}`)
    }
    :
    <div className="min-w-0 whitespace-nowrap overflow-hidden text-ellipsis">{title}</div>
  </div>
);

export const TableCellLinkAction = ({
  action,
  link,
  id,
  title,
}: {
  action: string;
  link: string;
  id: string;
  title?: string;
}) => (
  <div className={clsx('flex flex-row ')}>
    {
      // @ts-ignore
      i18next.t(`Activity.actions.${action}`)
    }
    :
    <div className="min-w-0">
      <NavLink to={constructRoute(link, id)}>
        <p
          className={clsx(
            'pl-[4px] text_link_hover cursor-pointer',
            'whitespace-nowrap overflow-hidden text-ellipsis'
          )}
        >
          {title}
        </p>
      </NavLink>
    </div>
  </div>
);

export const TableCellAction = (activity: Activity) => {
  switch (activity.action) {
    case ActivityAction.UserLoggedIn:
    case ActivityAction.UserAcceptedInvitation:
    case ActivityAction.UserUpdatedProfile:
    case ActivityAction.UserChangedPassword:
      return <TableCellBasicAction action={activity.action} />;
    case ActivityAction.AdminInvitedUser:
    case ActivityAction.AdminResendUserInvitation:
    case ActivityAction.AdminResetUserCredentials:
      return (
        <TableCellLinkAction
          action={activity.action}
          link={ROUTES.USER_ACCOUNT}
          id={activity.payload?.id ?? ''}
          title={activity.payload?.on}
        />
      );
    case ActivityAction.UserCreatedDocument:
    case ActivityAction.UserRequestedDocument:
    case ActivityAction.UserResendDocumentToParty:
    case ActivityAction.PartyRequestedDocument:
    case ActivityAction.PartySignedDocument:
    case ActivityAction.UserVerifierDocumentParty:
    case ActivityAction.UserRejectedDocumentParty:
    case ActivityAction.UserSharedDocument:
    case ActivityAction.PartyRequestedSharedDocument:
    case ActivityAction.AdminTransferredDocument:
      return (
        <TableCellLinkAction
          action={activity.action}
          link={ROUTES.DOCUMENT_DETAILS}
          id={activity.payload?.id ?? ''}
          title={activity.payload?.on}
        />
      );
    case ActivityAction.AdminDeletedUser:
      return (
        <TableCellDetailedAction action={activity.action} title={activity.payload?.on} />
      );
    default:
      return null;
  }
};
