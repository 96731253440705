import clsx from 'clsx';
import { ReactNode } from 'react';
import { createPortal } from 'react-dom';
import { Title } from '../Typography/Title';
import CloseIcon from '@/assets/icons/close-modal.svg';
import { useLockBodyScroll } from '@/utils/hooks';

interface ModalProps {
  isOpen: boolean;
  children: ReactNode;
  annotation?: string;
  onClose: () => void;
  title: string;
  size?: 'tiny' | 'super-small' | 'small' | 'medium' | 'wide';
}

export const Modal = ({
  isOpen,
  children,
  annotation,
  onClose,
  title,
  size,
}: ModalProps) => {
  useLockBodyScroll(isOpen);

  if (!isOpen) return null;

  let sizeStyle;

  switch (size) {
    case 'tiny': {
      sizeStyle = 'max-sm:w-[95%] sm:w-[60%] md:w-[50%] xl:w-[40%] 2xl:w-[30%]';
      break;
    }
    case 'super-small': {
      sizeStyle = 'max-sm:w-[95%] sm:w-[80%] md:w-[75%] xl:w-[50%] 2xl:w-[45%]';
      break;
    }
    case 'small': {
      sizeStyle = 'max-sm:w-[95%] sm:w-[80%] md:w-[75%] xl:w-[70%] 2xl:w-[60%]';
      break;
    }
    case 'medium': {
      sizeStyle = 'w-[75%] max-xl:w-[90%]';
      break;
    }
    case 'wide': {
      sizeStyle = 'w-[90%]';
      break;
    }
    default: {
      sizeStyle = 'max-2xl:w-[90%]';
      break;
    }
  }

  return createPortal(
    <div
      className={clsx(
        'fixed inset-0 background_modal z-[99]',
        'flex items-center justify-center flex-col'
      )}
    >
      {annotation && <p className="text-white-primary mb-[18px]">{annotation}</p>}
      <div className={clsx('background_secondary border_top overflow-auto', sizeStyle)}>
        <div
          className={clsx(
            'lg:py-[42px] py-7.5 p-12.5 max-xl:px-7.5 flex items-center',
            'justify-between'
          )}
        >
          <Title level="h4" className="!max-sm:leading-6">
            {title}
          </Title>
          <button type="button" onClick={onClose} aria-label="Close">
            <img src={CloseIcon} className="w-[32px] font-bold" alt="" />
          </button>
        </div>
        {children}
      </div>
    </div>,
    document.getElementById('modal') as HTMLElement
  );
};
