import { ROUTES } from '@/constants/routes';

export interface ICard {
  title: string;
  header: string;
  text: string;
  caption: string;
  url: string;
}

export const cardlinks: ICard[] = [
  {
    title: 'Documents',
    header: 'Documents Management',
    text:
      'Here you can see the list of all documents in the platform, ' +
      'create lists of documents meeting certain criteria, ' +
      'and transfer documents between users. You can also ' +
      'see the contents of documents and edit their metadata.',
    caption: 'View All Documents',
    url: ROUTES.DOCUMENTS,
  },
  {
    title: 'Users',
    header: 'User Management',
    text:
      'Here you can see the list of all users in the platform, ' +
      'create lists of users meeting certain criteria, ' +
      'and invite new users. You can see and edit the details ' +
      'of users and remove users from the platform.',
    caption: 'View All Users',
    url: ROUTES.USERS,
  },
  {
    title: 'Invitations',
    header: 'New User Management',
    text:
      'Here you can see the list of all pending users in the platform and ' +
      'invite new users.',
    caption: 'View Invited Users',
    url: ROUTES.INVITATIONS,
  },
  {
    title: 'Statistics',
    header: '',
    text: 'Here you can find information about the usage of the platform.',
    caption: ' View Statistics',
    url: ROUTES.STATISTICS,
  },
  {
    title: 'Administrator',
    header: '',
    text: 'Here you can find and edit your information, including your password.',
    caption: 'View Profile',
    url: ROUTES.ACCOUNT,
  },
  {
    title: 'Activity Log',
    header: '',
    text: 'Here you can find the log of activities performed on the platform by all users..',
    caption: 'View Activity Log',
    url: ROUTES.ACTIVITY,
  },
];
